module.exports = {
    theme: 'light',
    accent: 'gray',
    location: 'bottom',
    cookiesPolicyUrl: {
        es: '/cookies',
        eu: '/cookies',
    },
    persistentButton: true,
    types: {
        analytics: {
            active: true,
            label: 'analytics',
            lightboxcodetit: 'analytics-tit',
            lightboxcodedesc: 'analytics-desc',
        },
        marketing: {
            active: false,
            label: 'marketing',
            lightboxcodetit: 'marketing-tit',
            lightboxcodedesc: 'marketing-desc'
        }
    },
    services: {
        googleAnalytics: {
            active: true,
            label: 'Google Analytics',
            url: 'https://www.googletagmanager.com/gtag/js?id=$$code$$',
            async: true,
            code: 'G-SQNYGBYSPB',
            inlinejs: "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '$$code$$' );",
        },
        googleTagManager: {
            active: false,
            label: 'Google Tag Manager',
            code: '',
            inlinejs: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '$$code$$' );",
            noscript: '<iframe src="https://www.googletagmanager.com/ns.html?id=$$code$$" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
        },
        facebook: {
            active: false,
            label: 'Facebook',
            code: '',
            inlinejs: "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('consent', 'revoke');fbq('init', '$$code$$');fbq('track', 'PageView');",
            noscript: '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=$$code$$&ev=PageView&noscript=1"/>'
        }
    }
}
