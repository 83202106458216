<template>
    <div id="veiss-cookies">
        <!-- Config Lightbox -->
        <div v-if="renderLightbox" class="fixed w-screen h-screen inset-0 flex items-center justify-center">
            <div class="z-10 absolute max-w-5xl w-full">
                <div class="bg-white rounded-lg shadow-2xl px-6 py-12 lg-px-12 font-cookies m-4 text-xl lg-text-2xl leading-8 max-h-95 overflow-y-auto text-black text-left">
                    <h2 class="font-bold lg-text-3xl text-2xl mb-6">{{ $t('lightbox.tit') }}</h2>
                    <p class="mb-2">{{ $t('lightbox.ini1') }}</p>
                    <p class="mb-2">{{ $t('lightbox.ini2') }}</p>
                    <p class="mb-2" v-if="config.cookiesPolicyUrl">{{ $t('lightbox.ini3') }} <a :href="cookiePageUrl" target="_blank" class="border-b text-black border-black">{{ $t('mainform.policy') }}</a>.</p>

                    <div class="mt-12 pt-8 border-t border-gray-300">
                        <LightboxService
                            tit="mandatory"
                            desc="mandatorydesc"
                            :theme="config.theme"
                            :accent="config.accent"
                            :block="true"
                            name="mandatory"
                            label="mandatory"
                            :value="true"
                        />
                    </div>

                    <div v-for="(type,index) in config.types" :key="index">
                        <LightboxService v-if="type.active"
                            :tit="type.lightboxcodetit"
                            :desc="type.lightboxcodedesc"
                            :theme="config.theme"
                            :accent="config.accent"
                            :block="false"
                            :name="type.label"
                            :label="type.label"
                            :value="checkServiceActive( type.label )"
                        />
                    </div>

                    <div class="lg-flex justify-center text-center mt-4 pt-8 border-t border-gray-300">
                        <button class="mx-2 mb-2 bg-grey border border-gray-700 font-bold lg-py-6 lg-px-10 p-3 rounded-xl lg-leading-tight text-xl lg-text-2xl cursor-pointer text-black"
                            @click.prevent="allCookiesOutLightbox()"
                        >
                            <span class="inline-block">{{ $t('mainform.necessary') }}</span>
                        </button>

                        <button class="mx-2 mb-2 bg-grey border border-gray-700 font-bold lg-py-6 lg-px-10 p-3 rounded-xl lg-leading-tight text-xl lg-text-2xl cursor-pointer text-black"
                            @click.prevent="manualConfigIn()"
                        >
                            <span class="inline-block">{{ $t('mainform.buttonok') }}</span>
                        </button>

                        <button class="mx-2 mb-2 bg-grey border border-gray-700 font-bold lg-py-6 lg-px-10 p-3 rounded-xl lg-leading-tight text-xl lg-text-2xl cursor-pointer text-black"
                            @click.prevent="allCookiesLightbox()"
                        >
                            <span class="inline-block">{{ $t('mainform.allok') }}</span>
                        </button>

                    </div>
                </div>
            </div>
            <div class="bg-overlay bg-white w-full h-full"></div>
        </div>

        <!-- Persistent Button -->
        <div v-if="renderPill && pillStatus && currentUrl == cookiesUrl">
            <span class="fixed text-xl lg-text-2xl px-4 py-2 rounded-xl bottom-0 left-0 z-50 block mb-2 ml-2 shadow-2xl cursor-pointer font-cookies leading-tight text-black"
                :class="pillClasses"
                @click.prevent="openMainConf()"
            ><span class="block mt-1 font-bold text-black">{{ $t('confpill') }}</span></span>
        </div>

        <!-- Main Banner -->
        <div v-if="render" id="main-form" class="fixed w-screen h-screen inset-0 z-20 flex items-center justify-center">
            <div class="z-10 absolute max-w-5xl w-full">
                <div class="bg-white rounded-lg shadow-2xl px-6 py-12 lg-px-12 font-cookies mx-4 text-black text-left">
                    <div class="mb-2"><strong class="font-bold lg-text-3xl text-2xl">{{ $t('mainform.title') }}</strong></div>
                    <div :class="`text-${text} text-xl lg-text-2xl leading-8 text-left mb-8 pb-8 border-b border-gray-300`">
                        {{ $t('mainform.desc1') }}, <a href="#" @click.prevent="setUpConfForm()" class="border-b border-black text-black hover:text-primary">{{ $t('mainform.buttonconf') }}</a> {{ $t('mainform.desc2') }} <span class="inline-block" v-if="config.cookiesPolicyUrl">{{ $t('mainform.desc3') }} <a :href="cookiePageUrl" target="_blank" class="border-b border-black text-black hover:text-primary">{{ $t('mainform.policy') }}</a></span>
                    </div>

                    <div v-if="renderMain" class="flex items-center justify-center mt-2 lg-mt-2">
                        <button :class="`mx-2 bg-grey border border-gray-700 font-bold lg-py-6 lg-px-10 p-3 rounded-xl lg-leading-tight text-xl lg-text-2xl cursor-pointer text-black text-center`"
                            @click.prevent="allCookies()"
                        >
                            <span class="inline-block">{{ $t('mainform.allok') }}</span>
                        </button>

                        <button :class="`mx-2 bg-grey border border-gray-700 font-bold lg-py-6 lg-px-10 p-3 rounded-xl lg-leading-tight text-xl lg-text-2xl cursor-pointer text-black text-center`"
                            @click.prevent="allCookiesOut()"
                        >
                            <span class="inline-block">{{ $t('mainform.allnook') }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="bg-overlay bg-white w-full h-full"></div>
        </div>
    </div>
</template>

<script>
    // i18n plugin
    import i18n from './i18n'

    // Import config
    import config from '../veiss-cookies.config.js'

    // Tailwind CSS
    import styles from './assets/css/index.css'

    // Event bus
    import { EventBus } from './event-bus.js'

    // Components
    import toggle from './components/toggle'
    import toggleConf from './components/toggleConf'
    import LightboxService from './components/LightboxService'

    export default {
        styles,
        config,
        i18n,
        name: 'App',

        data() {
            return {
                render: false,
                renderMain: false,
                renderConf: false,
                renderPrevious: false,
                renderPill: false,
                renderLightbox: false,
                config: config,
                noService: 'noneAllowed',
                pillStatus: config.persistentButton,
                text: config.theme == 'dark' ? 'white' : 'black',
                activeServices: [],
                refuseAll: false,
                externalBtn: null,
                mainform: {
                    allok: false,
                    allnook: false,
                    config: false
                },
                consentMode: null,
                currentUrl: null,
                cookiesUrl: null
            }
        },

        components: {
            toggle,
            toggleConf,
            LightboxService
        },

        computed: {
            mainFormClasses: function() {
                let classes

                if(config.theme == 'dark') classes = 'bg-black'
                if(config.theme == 'light') classes = 'bg-white border-t border-black'

                if(config.location == 'top') classes += ' top-0'
                if(config.location == 'bottom') classes += ' bottom-0'

                return classes
            },

            pillClasses:  function() {
                let classes

                if(config.theme == 'dark') classes = 'bg-black opacity-75 text-white'
                if(config.theme == 'light') classes = 'bg-white text-black border border-gray-700 bg-grey border-solid'

                return classes
            },

            lightboxClasses: function() {
                let classes

                if(config.theme == 'dark') classes = 'bg-black opacity-75 text-white'
                if(config.theme == 'light') classes = 'bg-gray-100 text-black'

                return classes
            },

            cookiePageUrl: function () {
                let locale = this.$i18n.locale.slice(0, 2)
                let url = config.cookiesPolicyUrl[ locale ]
                return url
            }
        },

        methods: {
            closeLightbox() {
                this.renderLightbox = !this.renderLightbox
                this.renderPill = true
            },

            toogleChange(data) {
                if( data.name == "allok" ) {
                    this.mainform.allok = data.value
                    this.mainform.allnook = false
                    this.mainform.config = false
                    this.refuseAll = false
                }

                if( data.name == "allnook" ) {
                    this.mainform.allnook = data.value
                    this.mainform.allok = false
                    this.mainform.config = false
                    this.refuseAll = true
                }

                if( data.name == "config" ) {
                    this.mainform.config = data.value
                    this.mainform.allnook = false
                    this.mainform.allok = false
                }
            },

            toggleService(data) {
                if(data.value) {
                    this.activeServices.push(data.name)
                } else {
                    let index = this.activeServices.indexOf(data.name)
                    this.activeServices.splice(index, 1)
                }
            },

            allCookies() {
                this.allCookiesIn()
            },

            allCookiesLightbox() {
                this.allCookiesIn()
                this.closeLightbox()
            },
            
            allCookiesOutLightbox() {
                this.allCookiesOut()
                this.closeLightbox()
            },

            allCookiesOut() {
                this.consentUpdate({
                    'analytics_storage': 'denied',
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied'
                }, 'revoke');

                // Remove cookies
                let allCookies = document.cookie.split(';');
                let domain = window.location.hostname
                if(domain.includes("www")) domain = domain.slice(4)
                for (var i = 0; i < allCookies.length; i++) {
                    document.cookie = allCookies[i] + `=;expires=Mon Jan 01 1900 00:00:00 ; path=/ ; domain=.${domain}`;
                }
                
                Object.keys(config.services).forEach(s => {
                    let service = config.services[s]
                    if(service.active && service.inlinejs) {
                        this.activateInlineJs(service)
                    }
                });

                this.activeServices = [];
                this.activeServices.push(this.noService)
                this.setCookie( 'cookielaw' , JSON.stringify(this.activeServices) , 60 )
                this.render = false
                this.renderPill = true
            },

            allCookiesIn() {
                this.consentUpdate({
                    'analytics_storage': 'granted',
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted'
                }, 'grant');

                this.activeServices = [];
                
                Object.keys(config.types).forEach(t => {
                    let type = config.types[t]

                    if( type.active ) {
                        this.activeServices.push( type.label )
                    }
                })
                this.setCookie( 'cookielaw' , JSON.stringify(this.activeServices) , 60 )
                
                Object.keys(config.services).forEach(s => {
                    let service = config.services[s]
                    if(service.active && service.inlinejs) {
                        this.activateInlineJs(service)
                    }
                });
                
                this.render = false
                this.renderPill = true
            },

            activateInlineJs(service) {
                let eclass = this.slugify( service.label )
                if(document.getElementById( eclass + '-inline' ) && document.getElementById( eclass + '-inline' ).getAttribute('type') == 'text/plain') {
                    document.getElementById( eclass + '-inline' ).remove()
                    
                    let inline = service.inlinejs
                    let serviceJsExe = document.createElement('script')

                    serviceJsExe.setAttribute( 'id', eclass + '-inline' )
                    serviceJsExe.setAttribute( 'type', 'text/javascript' )
                    if( service.code ) inline = inline.replace('$$code$$', service.code)

                    let inlineScript = document.createTextNode( inline )
                    serviceJsExe.appendChild( inlineScript )
                    document.head.appendChild( serviceJsExe )
                }
            },

            manualConfigIn() {
                // Manual config override everything
                Object.keys(config.types).forEach(t => {
                    let type = config.types[t]

                    if(type.active) {
                        if( this.activeServices.indexOf( type.label ) != -1 ) {
                            this.activeService( type, false )
                        } else {
                            this.deactiveService( type )
                        }
                    }
                })

                // If no service allowed, save that
                if( this.activeServices.length == 0) this.activeServices.push(this.noService)
                this.setCookie( 'cookielaw' , JSON.stringify(this.activeServices) , 60 )
                this.render = false
                this.renderConf = false
                this.renderPill = true
                this.renderLightbox = false
            },

            persistentConfigIn(types) {
                Object.keys(config.types).forEach(t => {
                    let type = config.types[t]

                    if( type.active && types.indexOf( type.label ) != -1 ) {
                        this.activeService( type, true )
                    }
                })

                // If no service allowed, save that
                if( this.activeServices.length == 0) this.activeServices.push(this.noService)
                this.render = false
                this.renderConf = false
                this.renderPill = true

            },

            deactiveService( service ) {
                // Remove service from activeServices
                let index = this.activeServices.indexOf( service.label )
                if( index != -1 ) this.activeServices.splice( index, 1 )

                // Analytics cookies
                if(service.label == 'analytics') {
                    this.consentUpdate({
                        'analytics_storage': 'denied'
                    }, 'grant');
                }
                
                // Marketing cookies
                if(service.label == 'marketing') {
                    this.consentUpdate({
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied'
                    }, 'revoke');
                }
            },

            activeService( service, init ) {
                // Remove the not allowed service if exists
                if( this.activeServices.indexOf( this.noService ) != -1 ) {
                    let noIndex = this.activeServices.indexOf( this.noService )
                    this.activeServices.splice( noIndex, 1 )
                }

                // Analytics cookies
                if(service.label == 'analytics') {
                    this.consentUpdate({
                        'analytics_storage': 'granted'
                    });
                }
                
                // Marketing cookies
                if(service.label == 'marketing') {
                    this.consentUpdate({
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted'
                    }, 'grant');
                }

                // Activate inline js for every active service
                Object.keys(config.services).forEach(s => {
                    let service = config.services[s]
                    if(service.active && service.inlinejs) {
                        this.activateInlineJs(service)
                    }
                });
                
                // For the cookie storage
                if(init) this.activeServices.push( service.label )
            },

            checkServiceActive( label ) {
                if( this.activeServices.indexOf( label ) == -1 ) {
                    return false
                } else {
                    return true
                }
            },

            setUpConfForm() {
                this.render = false
                this.renderLightbox = true
            },

            back() {
                this.renderConf = false
                this.renderMain = true
            },

            slugify(str) {
                str = str.replace(/^\s+|\s+$/g, '');
                str = str.toLowerCase()

                // Remove accents, swap ñ for n, etc
                var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;"
                var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------"
                for (var i=0, l=from.length ; i<l ; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                // Remove invalid chars
                str = str.replace(/[^a-z0-9 -]/g, '')
                // Collapse whitespace and replace by -
                .replace(/\s+/g, '-')
                // Collapse dashes
                .replace(/-+/g, '-');

                return str
            },

            openMainConf() {
                this.renderPill = false
                this.render = true
                this.renderMain = true
            },

            /*
            *   Set cookie
            *   https://www.w3schools.com/js/js_cookies.asp
            */
            setCookie( cname , cvalue, exdays ) {

                document.cookie = cname + "=" + '' + ";expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/"

                var d = new Date()

                d.setTime(d.getTime() + (exdays*24*60*60*1000))

                var expires = "expires="+ d.toUTCString()

                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"

            },

            /*
            *   Get cookie
            *   https://www.w3schools.com/js/js_cookies.asp
            */
            getCookie( cname ) {
                var name = cname + "="
                var decodedCookie = decodeURIComponent(document.cookie)
                var ca = decodedCookie.split(';')

                for(var i = 0; i < ca.length; i++) {
                    var c = ca[i]

                    while (c.charAt(0) == ' ') {
                        c = c.substring(1)
                    }

                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length)
                    }
                }

                return ""
            },

            renderConsent(previousConf) {
                if(previousConf == null || previousConf.includes('noneAllowed') ) {
                    this.consentMode = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}
                    gtag('consent', 'default', {
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'denied'
                    })`;
                } else if(previousConf.includes('analytics') && previousConf.includes('marketing')) {
                    this.consentMode = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}
                    gtag('consent', 'default', {
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'analytics_storage': 'granted'
                    })`;
                } else if(previousConf.includes('analytics')) {
                    this.consentMode = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}
                    gtag('consent', 'default', {
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'granted'
                    })`;
                } else if(previousConf.includes('marketing')) {
                    this.consentMode = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}
                    gtag('consent', 'default', {
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'analytics_storage': 'denied'
                    })`;
                }
                let consentJsExe = document.createElement('script')
                consentJsExe.setAttribute( 'id', 'gtag-consentmode' )
                let consentModeScript = document.createTextNode( this.consentMode )

                consentJsExe.appendChild( consentModeScript )
                document.head.prepend( consentJsExe )
            },

            renderService( service, previousConf ) {                
                // Add JS to execute the service
                let eclass = this.slugify( service.label )
                if( service.inlinejs ) {
                    if(this.consentMode == null ) {
                        // Add JS for consent mode
                        this.renderConsent(previousConf)
                    }

                    // Add service inline JS
                    let inline = service.inlinejs
                    let serviceJsExe = document.createElement('script')

                    serviceJsExe.setAttribute( 'id', eclass + '-inline' )

                    // Load inline js as text/plain if consent is not defined
                    if(eclass != 'facebook' && previousConf == null) serviceJsExe.setAttribute( 'type', 'text/plain' )
                    else serviceJsExe.setAttribute( 'type', 'text/javascript' )

                    if( service.code ) inline = inline.replace('$$code$$', service.code)

                    let inlineScript = document.createTextNode( inline )

                    serviceJsExe.appendChild( inlineScript )
                    document.head.appendChild( serviceJsExe )
                }

                // Add service JS from external server
                if( service.url ) {
                    let url = service.url

                    if( service.code ) url = url.replace('$$code$$', service.code)
                    let serviceJsTag = document.createElement('script')

                    serviceJsTag.setAttribute( 'id', eclass + '-external' )
                    serviceJsTag.setAttribute('async', '')
                    serviceJsTag.setAttribute('src', url)
                    document.head.appendChild( serviceJsTag )
                }

                // Add noscript tag if needed
                if( service.noscript ) {
                    let serviceNoScriptHtml = service.noscript
                    let serviceNoScript = document.createElement('noscript')

                    serviceNoScript.setAttribute( 'id', eclass + '-noscript' )
                    if( service.code ) serviceNoScriptHtml = serviceNoScriptHtml.replace('$$code$$', service.code)

                    let serviceNoScriptContent = document.createTextNode( serviceNoScriptHtml )

                    serviceNoScript.appendChild( serviceNoScriptContent )
                    eclass == 'facebook' ? document.head.appendChild( serviceNoScript ) : document.body.prepend( serviceNoScript )
                }
            },
            
            consentUpdate(params, fb) {
                gtag('consent', 'update', params);
                if(config.services.facebook.active) fbq('consent', fb);
            },
        },

        mounted() {
            this.$nextTick(() => {  
                /*
                *   Open lightbox outside de Vue App
                *
                */
                this.externalBtn = document.getElementById('cookiesPop')

                if( this.externalBtn ) {
                    this.externalBtn.addEventListener('click', (e) => {
                        e.preventDefault()
                        this.setUpConfForm()
                    })
                }
            })                
            if(this.cookiePageUrl.lastIndexOf("/") == this.cookiePageUrl.length - 1) {
                this.cookiesUrl = this.cookiePageUrl.slice(0, -1)
            } else {
                this.cookiesUrl = this.cookiePageUrl
            }
        },

        created() {
            /*
            *   Get the language from the html tag
            */
            let languageHtml = document.getElementsByTagName('html')[0].getAttribute('lang')

            if(languageHtml) {
                this.$i18n.locale = languageHtml
            } else {
                this.$i18n.locale = 'es'
            }

            /*
            *   Check if there is previous configuration
            */
            let previousConf = []
            if( this.getCookie( 'cookielaw' ) != '' ) {
                previousConf = JSON.parse( this.getCookie( 'cookielaw' ) )
            }

            if( previousConf.length > 0 ) {
                /**
                 * Add services with previous consent
                 */
                Object.keys(config.services).forEach(s => {
                    let service = config.services[s]
                    
                    if(service.active) {
                        this.renderService(service, previousConf);
                    }
                })

                /**
                 * Update consent in relation to previous configuration
                 */
                let params = {}
                let fb = 'revoke';
                if(previousConf == null || previousConf.includes('noneAllowed') ) {
                    params = {
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'denied'
                    };
                    fb = 'revoke';
                } else if(previousConf.includes('analytics') && previousConf.includes('marketing')) {
                    params = {
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'analytics_storage': 'granted'
                    };
                    fb = 'grant';
                } else if(previousConf.includes('analytics')) {
                    params = {
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'granted'
                    };
                    fb = 'revoke';
                } else if(previousConf.includes('marketing')) {
                    params = {
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'analytics_storage': 'denied'
                    };
                    fb = 'grant';
                }
                this.consentUpdate(params, fb);

                /*
                *   Render the pill
                */
                this.renderPill = true
                if(window.location.pathname.lastIndexOf("/") == window.location.pathname.length - 1) {
                    this.currentUrl = window.location.pathname.slice(0, -1)
                } else {
                    this.currentUrl = window.location.pathname
                }
            } else {
                /*
                *   Render the full App
                */
                this.renderMain = true
                this.render = true

                /**
                 * Add services with previous consent
                 */
                Object.keys(config.services).forEach(s => {
                    let service = config.services[s]
                    
                    if(service.active) {
                        this.renderService(service, null);
                    }
                })
            }

            /**
             * Event bus
             *
             */
            EventBus.$on('toggleService', data =>  {
                this.toggleService( data )
            })
        }
    }
</script>

<style lang="css">
    @import url('./assets/css/index.css');

    #veiss-cookies {
        position: relative;
        z-index: 9999;
    }
    .bg-overlay {
        opacity: 0.6;
    }
</style>
